html, body, #root, .App {
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    background-color: #161616;
}
::selection
{
    background-color: grey;  
    color: black;
}